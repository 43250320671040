import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/second-chance.scss"

const SecondChanceProgram = () => {
  return (
    <Layout>
      <SEO
        title="Second Chance Program"
        description="It is time for a brand new, healthy, beautiful smile at Innovative Oral Surgery & Dental Implant Studio."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/second-chance-desktop-hero-image"
            alt="second chance banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/second-chance-mobile-hero-image"
            alt="second chance banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity for a Second Chance to Smile</h1>

            <p>
              Innovative Oral Surgery & Dental Implant Studio is proud to
              announce that this year’s Second Chance recipients are Shannon and
              April!!
            </p>

            <p>
              Shannon, 49, is a dedicated student working toward her real estate
              license. Having been a victim of domestic abuse for 15 years,
              she’s overcome significant physical and emotional trauma,
              including broken bones and shattered self-confidence. Now, as she
              nears the completion of her studies, Shannon believes that
              restoring her smile will empower her to step into the professional
              world with the confidence she deserves. Despite her past
              struggles, she sees herself not as a victim, but as a survivor who
              fought hard to reclaim her life. A new smile, Shannon said, would
              help her feel unstoppable, allowing her to embrace every
              opportunity ahead.
            </p>
            <p>
              April, 37, is a cashier who has struggled with her dental health
              for years, having not seen a dentist since she was 17. Currently,
              she uses snap-on fake teeth, which help boost her confidence and
              make customer interactions at work easier. April explained that
              restoring her smile would give her the confidence she needs to
              succeed both professionally and personally. Having recently left a
              difficult 17-year marriage, she is determined to rebuild her life
              and believes that a smile restoration could be a pivotal step in
              moving forward.{" "}
            </p>
            <p></p>

            <p>
              Shannon and April will receive a full set of customized,
              artificial teeth at no cost. This tooth replacement solution is
              called{" "}
              <Link
                to="/full-arch-restoration/"
                title="Learn more about full-arch-restoration">
                full-arch restoration
              </Link>{" "}
              because it replaces a full dental arch of missing or failing
              teeth. Our team of experts will work closely with a restorative
              dentist to complete the treatment.
            </p>
          </div>
        </div>

        {/* <div className="sas__section">
          <div className="sas__container">
            <ImageSlider
              useDots
              adaptiveHeight
              images={[
                "ELOS/DEV/second-chance-recipient-2023-1",
                "ELOS/DEV/second-chance-recipient-2023-2",
                "ELOS/DEV/second-chance-recipient-2023-3",
                "ELOS/DEV/second-chance-recipient-2023-4"
              ]}
            />
          </div>
        </div> */}

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              Follow this page,{" "}
              <a
                href="https://www.facebook.com/eastlouisvilleoralsurgery"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>{" "}
              to follow Shannon and April’s journeys to new smiles!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-louisville-ky/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondChanceProgram
